import Login from '@/views/UserLogin.vue';
import ListExercises from '@/views/ListExercises.vue';
import Exercise from '@/views/ExecuteExercise.vue';
import NotFound from '@/views/NotFound.vue';
import LogsPage from "@/views/LogsPage.vue";

const routes = [
    {
        path: '/',
        name: 'ListExercises',
        component: ListExercises,
    },
    {
        path: '/exercise',
        name: 'Exercise',
        component: Exercise
    },
    {
        path: '/logs',
        name: 'LogsPage',
        component: LogsPage
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: "/:catchAll(.*)",
        name: "NotFound",
        component: NotFound,
    }
]

export default routes;
