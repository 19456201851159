import {defineStore} from 'pinia';
import api from '@/api/api';

export const useLogsStore = defineStore('logs', {
    state: () => {
        return {
            hasPermission: false,
            isPermissionAlreadyQueried: false,
            simulatorLogs: [],
            chatLogs: []
        }
    },
    persist: true,
    getters: {},
    actions: {
        async init() {
            await this.verifyShouldNavigateToLogsPage();
            if (this.hasPermission) {
                await this.fetchLogs();
            }
        },
        async fetchLogs() {
            if (!this.hasPermission) {
                return;
            }

            await api().get('/log/simulator', {})
                .then(response => this.simulatorLogs = response.data)
                .catch(() => this.simulatorLogs = []);

            await api().get('/log/chat', {})
                .then(response => this.chatLogs = response.data)
                .catch(() => this.chatLogs = []);
        },
        async verifyShouldNavigateToLogsPage() {
            if (this.isPermissionAlreadyQueried) {
                return;
            }

            await api().get('/log/permission', {})
                .then(() => this.hasPermission = true)
                .catch(() => this.hasPermission = false)
                .finally(() => this.isPermissionAlreadyQueried = true);
        },
        async downloadSimulatorLogs() {
            await this.downloadLogs('simulator');
        },
        async downloadChatLogs() {
            await this.downloadLogs('chat');
        },
        async downloadLogs(logType) {
            await api().get(`log/${logType}/download`, {responseType: 'blob'})
                .then(response => {
                    const blob = new Blob([response.data], {type: 'text/csv;charset=utf-8;'});
                    const url = window.URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `logs-${logType}.csv`);
                    document.body.appendChild(link);
                    link.click();

                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                });
        }
    }
});