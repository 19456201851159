<template>
  <MainStructure>
    <div class="logs-container">
      <div class="logs-menu">
        <div class="file-list">
          <div class="file-list-header">
            <h3 class="file-list-title">Logs do simulador</h3>
            <AppIcons
                class="file-list-download"
                icon="filetype-csv"
                size="30"
                @click="downloadSimulatorLogs"/>
          </div>

          <ul class="file-list-ul">
            <li :class="'file-list-li' + (log.active ? ' active' : '')"
                v-for="log in store.simulatorLogs"
                @click="selectLog(log)"
                :title="log.title"
                v-bind:key="log.id">
              {{ log.title }}
            </li>
          </ul>
        </div>

        <div class="file-list">
          <div class="file-list-header">
            <h3 class="file-list-title">Logs de chat</h3>
            <AppIcons
                class="file-list-download"
                icon="filetype-csv"
                size="30"
                @click="downloadChatLogs"/>
          </div>

          <ul class="file-list-ul">
            <li :class="'file-list-li' + (log.active ? ' active' : '')"
                v-for="log in store.chatLogs"
                @click="selectLog(log)"
                :title="getChatLogTitle(log)"
                v-bind:key="log.id">
              {{ getChatLogTitle(log) }}
            </li>
          </ul>
        </div>
      </div>

      <div class="file-list-content">
        <div v-if="log">
          <div class="file-list-content-title">
            {{ getChatLogTitle(log) }}
          </div>

          <div v-html="getFormattedContent(log.content)"></div>
        </div>

        <p v-else>Selecione um log para visualizar o conteúdo.</p>
      </div>
    </div>
  </MainStructure>
</template>

<script setup>
import MainStructure from '@/components/layoutParts/MainStructure.vue';
import {onMounted, ref} from 'vue';

import {useLogsStore} from "@/stores/logs";
import {useRouter} from "vue-router";
import AppIcons from "@/components/layoutParts/AppIcons.vue";

const router = useRouter();
const store = useLogsStore();
const log = ref(null);

const selectLog = (selectedLog) => {
  log.value = selectedLog;
  store.simulatorLogs.forEach((l) => {
    l.active = l.id === selectedLog.id;
  });
  store.chatLogs.forEach((l) => {
    l.active = l['session_id'] === selectedLog['session_id'];
  });
}

const downloadSimulatorLogs = () => {
  store.downloadSimulatorLogs();
}

const downloadChatLogs = () => {
  store.downloadChatLogs();
}

const getChatLogTitle = (logToBeFormatted) => {
  if (logToBeFormatted['title']) {
    return logToBeFormatted['title'];
  }

  const date = new Date(logToBeFormatted['created_at']);
  return date.toLocaleString('pt-br') + ', Session ID ' + logToBeFormatted['session_id'] + ', Exercício ' + logToBeFormatted['exercise'] + ', Estudante ' + logToBeFormatted['student'];
}

const getFormattedContent = (content) => {
  if (typeof content === 'string') {
    return content;
  }

  return content.map(message => {
    const speaker = message.type === 'human' ? 'Estudante' : 'IA';
    return `<b>${speaker}</b>: ${message.content}`;
  }).join('\n\n');
}

onMounted(async () => {
  await store.init();
  if (!store.hasPermission) {
    await router.push({name: 'ListExercises'});
  }
})

</script>

<style scoped>
.logs-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.logs-menu {
  width: 25%;
  max-width: 300px;
  height: 100%;
}

.file-list {
  height: 50%;
  border-right: 1px solid #E8EFFB;
  border-top: 1px solid #E8EFFB;
  overflow-y: auto;
  color: #1e1e1e;
  padding: 10px;
}

.file-list-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.file-list-title {
  flex-grow: 1;
  margin-bottom: unset !important;
  color: var(--bs-secondary-color) !important;
}

.file-list-download {
  margin-right: 6px;
}

.file-list-download:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: .2s;
}

.file-list-ul {
  list-style: none;
}

.file-list-li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #e8effb;
  border-radius: 8px;
  color: #212529;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.file-list-li:hover {
  background-color: #f4f7fc;
  transform: scale(1.06);
  transition: .2s;
}

.file-list-li.active {
  background-color: #007acc;
  color: white;
}

.file-list-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  white-space: pre-wrap;
  color: #212529;
}

.file-list-content-title {
  font-size: 1.5rem;
  margin-bottom: 20px;
}
</style>
